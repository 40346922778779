import React from 'react';
import { Link } from 'gatsby';
import SEO from '../../components/Seo';
import { formatDate } from '../../util';
import ContentContainer from '../common/ContentContainer';
import ContentfulContent from '../common/ContentfulContent';
import Layout from '../Layout';
import Author from './../blog/Author';
import './../layout.css';
import './LayoutCommon.css';

type BlogPost = {
  title: string;
  publishedDate: string;
  slug: string;
  author: {
    firstName: string;
    lastName: string;
  };
  featuredImage: {
    fluid: {
      src: string;
    };
  };
};

interface OtherBlogPostProps {
  blogPost: BlogPost;
}

const OtherBlogPost: React.FC<OtherBlogPostProps> = props => {
  return (
    <Link to={`/blog/${props.blogPost.slug}`}>
      <div className="bg-white rounded-lg shadow mt-4 p-4 cursor-pointer flex">
        <div className="">
          <h3 className="text-sm text-gray-700 font-semibold">
            {props.blogPost.title}
          </h3>
          <p className="text-xs text-gray-500 uppercase mt-1">
            {props.blogPost.author.firstName} {props.blogPost.author.lastName} |{' '}
            {formatDate(new Date(props.blogPost.publishedDate), true)}
          </p>
        </div>
      </div>
    </Link>
  );
};

interface OtherBlogPostsProps {
  blogPosts: BlogPost[];
}

const OtherBlogPosts: React.FC<OtherBlogPostsProps> = props => {
  return (
    <>
      {props.blogPosts.map((blogPost: BlogPost, i: number) => {
        return <OtherBlogPost key={i} blogPost={blogPost} />;
      })}
    </>
  );
};

interface BlogPostContentProps {
  pageData: any;
}

const BlogPostContent = (props: BlogPostContentProps) => {
  return (
    <>
      {props.pageData?.featuredImage && (
        <div
          className="rounded-t-lg h-96 w-full bg-cover"
          style={{
            backgroundImage: props.pageData?.featuredImage?.fluid?.src
              ? `url(${props.pageData?.featuredImage?.fluid?.src})`
              : ''
          }}
        ></div>
      )}
      <section className="bg-white rounded-lg shadow-md p-12">
        <h1 className="font-bold text-3xl text-gray-800">
          {props.pageData?.title}
        </h1>
        <p className="text-gray-700 mt-4">
          Published {formatDate(new Date(props.pageData?.publishedDate))}
        </p>
        <Author
          author={{
            imageSrc: props.pageData?.author?.image?.fluid?.src,
            firstName: props.pageData?.author.firstName,
            lastName: props.pageData?.author?.lastName
          }}
        />
        <section className="mt-10 content-body">
          <ContentfulContent content={props.pageData?.body.body} />
        </section>
      </section>
    </>
  );
};

const BlogPostLayout: React.FC = ({ pageContext }: any) => {
  return (
    <Layout>
      <SEO title={pageContext?.pageData?.title} />
      <ContentContainer>
        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-2/3 h-full mr-4">
            <BlogPostContent pageData={pageContext?.pageData} />
          </div>
          <div className="w-full lg:w-1/3 h-full ml-0 lg:ml-4 mt-8 lg:mt-0">
            <aside className="px-0 lg:px-8 pt-0">
              <p className="font-bold text-primary sans-serif text-xl">
                Other Blog Posts
              </p>
              <OtherBlogPosts blogPosts={pageContext?.latestBlogPosts} />
            </aside>
          </div>
        </div>
      </ContentContainer>
    </Layout>
  );
};

export default BlogPostLayout;
